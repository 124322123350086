import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FullCalendar, {
      ref: "calendar",
      options: _ctx.calOptions
    }, null, 8, ["options"])
  ]))
}