
import "@fullcalendar/core/vdom";
import FullCalendar, {CalendarOptions, EventClickArg} from '@fullcalendar/vue3';
import {
  defineComponent, PropType, ref,
} from "vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  components: {FullCalendar},
  setup() {
    const calendar = ref<InstanceType<typeof FullCalendar>>();
    return {calendar}
  },
  props: {
    calls: {
      type: Object as PropType<Array<Object>>,
      required: true
    },
    eventSources: {
      type: Object as PropType<Array<Object>>,
      required: true
    }
  },
  data() {
    return {
      calOptions: {
        themeSystem: 'bootstrap',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        eventDisplay: 'block',
        initialView: "dayGridMonth",
        headerToolbar: false,
        aspectRatio: 2,
        views: {
          dayGridMonth: {buttonText: "month"},
          timeGridWeek: {buttonText: "week"},
          timeGridDay: {buttonText: "day"}
        },
        dayMaxEvents: true,
        eventSources: this.eventSources,
        datesSet: () => this.print(),
        eventClick: (args: EventClickArg) => {
          const userStore = useUser();
          const event = args.event;
          const call = {
            venueName: event.extendedProps.venueName,
            start: event.start,
            callId: event.extendedProps.callId,
            callTypeId: event.extendedProps.callTypeId,
            callTypeName: event.extendedProps.callTypeName,
            callTypeColor: event.extendedProps.callTypeColor,
            end: event.end,
            description: event.extendedProps.description,
            productionId: event.extendedProps.productionId,
            productionName: event.extendedProps.productionName,
            title: event.title,
            venueSpaceName: event.extendedProps.venueSpaceName,
            accountName: event.extendedProps.accountName,
            isConflict: event.extendedProps.isConflict,
            conflictId: event.extendedProps.conflictId,
            isFlexible: event.extendedProps.isFlexible,
            isPrivate: event.extendedProps.isPrivate,
            resourceId: event.extendedProps.resourceId,
            classNames: event.extendedProps.classNames,
            allDay: event.extendedProps.allDay
          } as CallCalendarEvent;

          const isOwnConflict = call.isConflict && call.resourceId?.toUpperCase() === userStore.profile.accountId.toUpperCase()
          if (call.isConflict && isOwnConflict) {
            bus.emit('modal:conflicts:updateExternal', call.conflictId)
          } else if (!call.isConflict && userStore.canManage(call.productionId) && !this.isOrganization) {
            bus.emit('modal:scheduling:update', call)
          } else {
            bus.emit('modal:calls:viewer:view', call)
          }
        },
        eventDidMount: (args) => {
          const userStore = useUser();
          const accountName = args.event.extendedProps.accountName
          const isOwnConflict = args.event.extendedProps.isConflict && args.event.extendedProps.resourceId?.toUpperCase() === userStore.profile.accountId.toUpperCase();
          if (args.event.extendedProps.isConflict && !isOwnConflict) {
            args.el.querySelector('.fc-event-title')!.textContent = `[Conflict] ${accountName}`
          }
        }
      } as CalendarOptions,
      showEveryone: false,
      selectedCallTypes: [],
      selectedProductions: []
    }
  },
  methods: {
    print() {
      bus.emit('calendar:update:activeStart', this.calendar?.getApi().view.title)
    },
    redraw() {
      this.calendar?.getApi().refetchEvents();
    }
  },
  computed: {
    callTypes(): Array<string> {
      return [];
    },
    productions(): Array<string> {
      return [];
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
  },
  mounted() {
    bus.on('modal:scheduling:added', () => {
      this.redraw()
    })
    bus.on('modal:scheduling:updated', () => {
      this.redraw()
    })
    bus.on('calendar:actions:previous', () => {
      this.calendar?.getApi().prev()
    })
    bus.on('calendar:actions:next', () => {
      this.calendar?.getApi().next()
    })
    bus.on('calendar:actions:today', () => {
      this.calendar?.getApi().today()
    })
    bus.on('calendar:actions:changeView', (viewType) => {
      this.calendar?.getApi().changeView(viewType as string)
    })
  },
});
